<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>申請加盟列表</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <v-client-table :data="users" :columns="tableConfigs.columns" :options="tableConfigs.options">
          <template slot="nickname" slot-scope="props">
            <el-avatar :src="props.row.headimgurl"></el-avatar>
            {{ props.row.nickname }}
          </template>

          <template slot="location" slot-scope="props">
            {{ props.row.country }}
            {{ props.row.province }}
            {{ props.row.city }}
          </template>

        </v-client-table>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'JoinUs',
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      users: [],
      tableConfigs: {
        columns: ['business', 'company_name', 'franchise_store_address', 'name', 'phone', 'email', 'ip', 'created_at'],
        options: {
          headings: {
            business: '公司業務',
            company_name: '公司名稱',
            franchise_store_address: '加盟點地址',
            name: '聯繫人姓名',
            phone: '聯繫人電話',
            email: '聯繫人電郵',
            ip: 'IP',
            created_at: '創建時間',
            // operation: '操作',
          },
          filterable: true,
          sortable: [],
          perPage: 50,
          perPageValues: [50, 100, 200, 300],
        },
      },
    };
  },
  computed: {},
  methods: {
    fetchData() {
      const loading = getLoadingInstance();
      this.$api.joinUs.get().then(({ data: response }) => {
        this.users = [...response.data];
      }).catch(errors => showErrorMessage(errors)).finally(() => {
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss">

</style>
